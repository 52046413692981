import "./GraphWindow.css";
import upgrade from "../../static/upgrade.svg";
import downgrade from "../../static/downgrade.svg";
import Loader from "../Loader/Loader";
import Graph from "../Graph/Graph";
import { useEffect, useRef, useState } from "react";
import useMainView from "../../hooks/useMainView";
import useGraphParams from "../../hooks/useGraphParams";
import useInfoPopup from "../../hooks/useInfoPopup";
import axios from "axios";
import InputNumber from "../InputNumber/InputNumber";
import * as XLSX from "xlsx";
import download from "../../static/download_blue_16.svg";
import InputDate from "../InputDate/InputDate";
import useGraphDate from "../../hooks/useGraphDate";
import DesData from "../DesData/DesData";
import useDates from "../../hooks/MarketData/useDates";
import DVDData from "../DVDData/DVDData";
import { GraphDataStructure } from "../../structures/GraphDataStructure";
import VolData from "../VolData/VolData";
import { set_is_local_window } from "../../redux/Common/actions";
import { useDispatch } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { ChartHistory } from "../ChartHistory/ChartHistory";
import { getFavorites, setChartHistory } from "../../utils/localStorageHandler";
import { GraphBuilder } from "../../structures/GraphBuilder";
import { ManagerMultiple } from "../MultipleGraph/ManagerMultiple";
import ModalWindow from "../ModalWindow/ModalWindow";

const GraphWindow = ({ loadingGraph }) => {
  // const dataObj = useRef(new GraphDataStructure())
  const dispatch = useDispatch();
  const { get } = useRequest();
  const infoPopup = useInfoPopup();
  const mainView = useMainView();
  const IsMobile = window.outerWidth < 768;
  const modes = ["3M", "6M", "1Y", "5Y", "Custom"];
  const graphParams = useGraphParams();
  const [view, setView] = useState();
  const graphDate = useGraphDate();
  const [pricingDate, setPricingDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [modesSelected, setModesSelected] = useState(modes[2]);
  const [isCustomMode, setIsCustomMode] = useState(false);
  const [isDes, setIsDes] = useState(false);
  const [isNonGraph, setIsNonGraph] = useState(false);
  const [candSelected, setCandSelected] = useState("1m");
  const [desData, setDesData] = useState({});
  const [volData, setVolData] = useState([]);
  const [leftLotSizeIHS, setLeftLotSizeIHS] = useState(1);
  const [rightLotSizeIHS, setRightLotSizeIHS] = useState(1);
  const [leftLotSizeIHST, setLeftLotSizeIHST] = useState(1);
  const [rightLotSizeIHST, setRightLotSizeIHST] = useState(1);
  const [isLiveMode, setIsLiveMode] = useState(true);
  const [liveID, setLiveID] = useState(null);
  let date = new Date();
  date.setHours(7, 0, 0, 0);
  let utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 16);
  console.log("ttime", utcDate, date);
  const [dateStartIHS, setDateStartIHS] = useState(utcDate); //
  const [dateEndIHS, setDateEndIHS] = useState(
    new Date(new Date() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16)
  ); //
  const [dateStartIHST, setDateStartIHST] = useState(utcDate); //
  const [dateEndIHST, setDateEndIHST] = useState(
    new Date(new Date() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16)
  ); //
  const [dateStartIGP, setDateStartIGP] = useState(utcDate); //
  const [dateEndIGP, setDateEndIGP] = useState(
    new Date(new Date() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16)
  ); //
  const [dateStartIGPCT, setDateStartIGPCT] = useState(utcDate); //
  const [dateEndIGPCT, setDateEndIGPCT] = useState(
    new Date(new Date() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16)
  ); //
  const linkToDateEndIHS = useRef(dateEndIHS);
  const linkToDateStartIHS = useRef(dateStartIHS);
  const linkToDateEndIHST = useRef(dateEndIHST);
  const linkToDateStartIHST = useRef(dateStartIHST);
  const linkToDateEndIGP = useRef(dateEndIGP);
  const linkToDateStartIGP = useRef(dateStartIGP);
  const linkToDateEndIGSV = useRef(dateEndIGP);
  const linkToDateStartIGSV = useRef(dateStartIGP);
  const linkToDateEndIGPCT = useRef(dateEndIGPCT);
  const linkToDateStartIGPCT = useRef(dateStartIGPCT);
  const realTimeCommands = [
    "IGP",
    "IGPC",
    "IHS",
    "IHST",
    "IGPCT",
    "OMON",
    "DASH",
    "IRS",
    "IGVT",
  ];
  const commonCMD = [
    "IGV",
    "IRST",
    "OMON",
    "IHS",
    "IGSV",
    "DASH",
    "HELP",
    "IHST",
    "IRS",
    "IGVT",
    "IGPC",
    "IGPCT",
    "IGBA",
    "IGBAT",
    "IVS",
    "IVST",
    "CLOSE",
  ];
  const [metrics, setMetrics] = useState({
    level: "",
    last_date: "",
    prev_level: "",
    last_delta: "",
    prev_date: "",
    week_delta_val: "",
    year_delta_val: "",
    year_delta: "",
    max_level: "",
    min_level: "",
    min_date: "00.00.0000",
    max_date: "00.00.0000",

    last_value: {
      value: [],
      date: "00.00.0000",
    },
    previous_value: {
      value: "",
      date: "",
      delta: "",
      delta_percent: "",
    },
    last_week: {
      value: "",
      delta_percent: "",
    },
    last_year: {
      value: "",
      delta_percent: "",
    },
    min_year: {
      value: "",
      date: "00.00.0000",
    },
    max_year: {
      value: "",
      date: "00.00.0000",
    },
  });
  const command = mainView.command;

  const validCommand = command.split(" ").map((elem) => elem.toUpperCase());
  const [data, setData] = useState(new GraphDataStructure());
  const modeInfo = useInfoPopup();
  const MODES = {
    "1M": 30,
    "3M": 90,
    "6M": 180,
    "1Y": 365,
    "2Y": 730,
    "5Y": 1825,
    "10Y": 3650,
  };
  const links = {
    IGV: {
      url: "/chart/option_candles",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: utcDate,
          end_time: new Date(
            new Date() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
          trades: false,
          algo: false,
        };
      },
    },
    IGVT: {
      url: "/chart/option_candles",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: utcDate,
          end_time: new Date(
            new Date() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
          trades: true,
          algo: true,
        };
      },
    },
    IGSV: {
      url: "/chart/put_and_call_option",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: new Date(linkToDateStartIGSV.current)
            .toISOString()
            .slice(0, 16),
          end_time: new Date(linkToDateEndIGSV.current)
            .toISOString()
            .slice(0, 16),
        };
      },
    },
    IRST: {
      url: "/chart/futures_implied_rate_and_trades",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: utcDate,
          end_time: new Date(
            new Date() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
          trades: true,
          algo: true,
        };
      },
    },
    IRS: {
      url: "/chart/futures_implied_rate_and_trades",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: utcDate,
          end_time: new Date(
            new Date() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
          trades: false,
          algo: false,
        };
      },
    },
    OMON: {
      url: "/chart/option_table",
      isGraph: false,
      params: (tickers) => {
        return {
          instrument: tickers[0],
        };
      },
    },
    IHS: {
      url: "/chart/spread",
      isGraph: true,
      params: (tickers) => {
        return {
          l_asset: tickers[0],
          r_asset: tickers[1] ? tickers[1] : "*",
          start_time: linkToDateStartIHS.current,
          end_time: linkToDateEndIHS.current,
          l_lot_size: leftLotSizeIHS,
          r_lot_size: rightLotSizeIHS,
          trades: false,
          algo: false,
        };
      },
    },
    IHST: {
      url: "/chart/spread",
      isGraph: true,
      params: (tickers) => {
        return {
          l_asset: tickers[0],
          r_asset: tickers[1] ? tickers[1] : "*",
          start_time: linkToDateStartIHS.current,
          end_time: linkToDateEndIHS.current,
          l_lot_size: leftLotSizeIHS,
          r_lot_size: rightLotSizeIHS,
          trades: true,
          algo: true,
        };
      },
    },
    DASH: {
      url: "/chart/algo_state",
      isGraph: false,
      params: (tickers) => {
        return {
          instrument: tickers[0],
        };
      },
    },
    HELP: {
      url: "/chart/help",
      isGraph: false,
      params: (tickers) => {
        return {
          instrument: tickers[0],
        };
      },
    },
    IGPC: {
      url: "/chart/candles_quotes",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: new Date(linkToDateStartIGP.current)
            .toISOString()
            .slice(0, 16),
          end_time: new Date(linkToDateEndIGP.current)
            .toISOString()
            .slice(0, 16),
          interval: candSelected,
          limit: 100000,
          trades: false,
          algo: false,
        };
      },
    },
    IGPCT: {
      url: "/chart/candles_quotes",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: new Date(linkToDateStartIGP.current)
            .toISOString()
            .slice(0, 16),
          end_time: new Date(linkToDateEndIGP.current)
            .toISOString()
            .slice(0, 16),
          interval: candSelected,
          limit: 100000,
          trades: true,
          algo: true,
        };
      },
    },
    IGBA: {
      url: "/chart/intraday_bid_ask",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: new Date(linkToDateStartIGPCT.current)
            .toISOString()
            .slice(0, 16),
          end_time: new Date(linkToDateEndIGPCT.current)
            .toISOString()
            .slice(0, 16),
          trades: false,
          algo: false,
        };
      },
    },
    IGBAT: {
      url: "/chart/intraday_bid_ask",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          start_time: new Date(linkToDateStartIGPCT.current)
            .toISOString()
            .slice(0, 16),
          end_time: new Date(linkToDateEndIGPCT.current)
            .toISOString()
            .slice(0, 16),
          trades: true,
          algo: true,
        };
      },
    },
    IVS: {
      url: "/chart/volatility_smile",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          time: tickers[1],
          trades: false,
        };
      },
    },
    IVST: {
      url: "/chart/volatility_smile",
      isGraph: true,
      params: (tickers) => {
        return {
          instrument: tickers[0],
          time: tickers[1],
          trades: true,
        };
      },
    },
    CLOSE: {
      url: "/chart/close_price",
      isGraph: false,
      params: (tickers) => {
        return {
          instrument: tickers[0] ? tickers[0] : "*",
        };
      },
    },
  };

  const getParams = () => {};

  const requestManager = async (preparedTickers) => {
    console.log(
      "time",
      utcDate,
      new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16)
    );
    setIsNonGraph(!links[validCommand[validCommand.length - 1]].isGraph);
    const rData = await get(
      links[validCommand[validCommand.length - 1]].url,
      {
        params:
          links[validCommand[validCommand.length - 1]].params(preparedTickers),
      },
      () => {},
      () => {}
    );
    return rData;
  };

  const action = async () => {
    // const pastDate = new Date(new Date().setDate(new Date().getDate() - MODES[modesSelected])).toISOString().slice(0, 10)
    const preparedTickers = validCommand
      .slice(0, -1)
      .map((elem) => elem.replaceAll("_", " "));

    if (validCommand[validCommand.length - 1] === "DES") {
      setIsDes(true);
      setIsNonGraph(true);
      get(
        "https://api.rumberg.ru/charts/render/",
        {
          params: {
            tickers: JSON.stringify(preparedTickers),
            command: validCommand[validCommand.length - 1],
            // settings: JSON.stringify({
            //     start: validCommand[validCommand.length - 1] !== "ZC" ? graphDate.graphDate : undefined,
            //     pars: graphParams.graphParams[mainView.mainView.toLowerCase()],
            //     pricing_date: validCommand[validCommand.length - 1] === "ZC" ? pricingDate : undefined
            // })
          },
        },
        () => {},
        () => {}
      )
        .then((data) => {
          const desData_ = data;
          console.log("[ DES DATA ]", desData_);
          if (typeof desData_ !== "object") {
            throw new Error("Invalid type of data");
          }
          const result = {};
          const blackList = [
            "ac_strikes",
            "barrier_types",
            "class_name",
            "cpn_strikes",
            "currency",
            "fixing_dates",
            "ref_spots",
            "typology",
            "digital_spreads",
          ];
          Object.keys(desData_).forEach((elem) => {
            console.log(elem, desData_[elem]);
            const tmp = [];
            if (typeof desData_[elem] !== "object") {
              throw new Error("Invalid type of inner data");
            }
            Object.keys(desData_[elem]).forEach((item) => {
              if (item === "structured") {
                if (
                  desData_[elem][item].product &&
                  typeof desData_[elem][item].product === "object"
                ) {
                  tmp.push({
                    title: "Structured Details:",
                    value: null,
                    type: "title",
                  });
                  Object.keys(desData_[elem][item].product).forEach((i) => {
                    if (!blackList.includes(i)) {
                      if (Array.isArray(desData_[elem][item]["product"][i])) {
                        tmp.push({
                          title: i,
                          value: desData_[elem][item]["product"][i].join(", "),
                        });
                        return;
                      }
                      let target = desData_[elem][item]["product"][i];
                      if (typeof target === "number")
                        target = target.toFixed(2);
                      if (typeof target === "boolean")
                        target = target ? "Yes" : "No";
                      tmp.push({
                        title: i,
                        value: target,
                      });
                    }
                  });

                  if (
                    desData_[elem][item]["product"]["ac_strikes"] &&
                    desData_[elem][item]["product"]["cpn_strikes"] &&
                    desData_[elem][item]["product"]["fixing_dates"]
                  ) {
                    const strikes = [];
                    desData_[elem][item]["product"]["ac_strikes"].forEach(
                      (tt, i) => {
                        strikes.push({
                          ac_strike: tt,
                          cpn_strike:
                            desData_[elem][item]["product"]["cpn_strikes"][i],
                          fixing_date:
                            desData_[elem][item]["product"]["fixing_dates"][i],
                        });
                      }
                    );
                    tmp.push({
                      title: "strikes",
                      value: "show",
                      data: strikes,
                      type: "strike",
                      length:
                        desData_[elem][item]["product"]["ac_strikes"].length,
                    });
                  }
                }
                tmp.push({
                  title: item,
                  value: "price",
                  data: desData_[elem][item],
                });
                return;
              }
              if (Array.isArray(desData_[elem][item])) {
                if (desData_[elem][item].length !== 0) {
                  tmp.push({
                    title: item,
                    value: "show",
                    data: desData_[elem][item],
                    type: "coupon",
                  });
                }
              } else {
                tmp.push({
                  title: item,
                  value: desData_[elem][item],
                });
              }
            });
            result[elem] = tmp;
          });
          // console.log("[ RESULT ~ ]", result, desData_)
          setDesData(result);
        })
        .catch((err) => {
          console.error(err);
          modeInfo.setPopupStatus(true, "Ошибка", false);
        });
    } else if (validCommand[validCommand.length - 1] === "DVD") {
      setIsDes(true);
      setIsNonGraph(true);
      setDesData({});
      console.log("DVD", preparedTickers);
      get(
        "https://api.rumberg.ru/market_data/dividends/",
        {
          params: {
            tickers: JSON.stringify(preparedTickers),
            marketdataset: "DEFAULT",
            pricing_date: new Date().toISOString().slice(0, 10),
          },
        },
        () => {},
        () => {}
      )
        .then((data) => {
          console.log("DVD 2", data);
          console.log(data, typeof data);
          setView(data);
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (validCommand[validCommand.length - 1] === "OVDV") {
      setIsDes(true);
      setIsNonGraph(true);
      setDesData({});
      const rData = await get(
        "https://api.rumberg.ru/market_data/volatilities/",
        {
          params: {
            marketdataset: "DEFAULT",
            tickers: JSON.stringify(preparedTickers),
            pricing_date: new Date().toISOString().slice(0, 10),
          },
        },
        () => {},
        () => {}
      );
      setVolData(rData);
      console.log(rData);
    } else if (validCommand[validCommand.length - 1] === "IGP") {
      // || validCommand[validCommand.length - 1] === "IGPC"
      setIsDes(false);
      setIsNonGraph(false);
      const now = new Date().toUTCString();
      if (!isLiveMode) {
        dispatch(set_is_local_window(true));
      }
      try {
        const rData = await get(
          "/candles_quotes",
          {
            params: {
              instrument: preparedTickers[0],
              start_time: new Date(linkToDateStartIGP.current)
                .toISOString()
                .slice(0, 16),
              end_time: new Date(linkToDateEndIGP.current)
                .toISOString()
                .slice(0, 16),
              interval: candSelected,
              limit: 100000,
              trades: false,
              algo: false,
            },
          },
          () => {},
          () => {}
        );
        // const rData = await axios.get("http://158.160.54.114:1338/short_candles_quotes?instrument=SBER.TQBR&start_time=2024-03-15T10:34&end_time=2024-03-15T15:00&limit=10000&interval=1m")
        console.log("IGP(C) rData: ", rData);
        const d = new GraphDataStructure();
        d.updateState(
          { data: rData },
          validCommand[validCommand.length - 1],
          validCommand[0]
        );
        setData(d);
      } catch (e) {
        infoPopup.setPopupStatus(true, e.message, false);
        console.error(e);
      } finally {
        dispatch(set_is_local_window(false));
      }
    } else if (commonCMD.includes(validCommand[validCommand.length - 1])) {
      setIsDes(false);
      if (!isLiveMode) {
        dispatch(set_is_local_window(true));
      }
      const rData = await requestManager(preparedTickers);
      if (rData.status === 1) {
        infoPopup.setPopupStatus(true, rData.description, false);
        dispatch(set_is_local_window(false));
        return;
      }
      const d = new GraphBuilder();
      d.setData(rData);
      setData(d);
      dispatch(set_is_local_window(false));
      console.log("-> NN <-", d.data, rData);
    } else if (
      validCommand[validCommand.length - 1] === "FVT" ||
      validCommand[validCommand.length - 1] === "MIVS" ||
      validCommand[validCommand.length - 1] === "MIVST"
    ) {
      return;
    } else {
      if (
        !Array.isArray(preparedTickers) ||
        preparedTickers.length === 0 ||
        !Array.isArray(validCommand) ||
        validCommand.length === 0 ||
        !validCommand[validCommand.length - 1]
      ) {
        //   // Здесь можно выдать какую-то заглушку или просто вернуть
        const rData = await get(
          "https://api.rumberg.ru/charts/render/",
          {
            params: {
              settings: JSON.stringify({
                start:
                  validCommand[validCommand.length - 1] !== "ZC"
                    ? graphDate.graphDate
                    : undefined,
                params: data.settings,
                pricing_date:
                  validCommand[validCommand.length - 1] === "ZC"
                    ? pricingDate
                    : undefined,
              }),
            },
          },
          () => {},
          () => {}
        );
        const d = new GraphDataStructure();
        d.updateState({ data: rData }, validCommand[validCommand.length - 1]);
        setData(d);
        return;
      }
      // setIsDes(false);
      // setIsNonGraph(false);
      // const rData = await get(
      //   "https://api.rumberg.ru/charts/render/",
      //   {
      //     params: {
      //       tickers: JSON.stringify(preparedTickers),
      //       command: validCommand[validCommand.length - 1],
      //       settings: JSON.stringify({
      //         start:
      //           validCommand[validCommand.length - 1] !== "ZC"
      //             ? graphDate.graphDate
      //             : undefined,
      //         params: data.settings,
      //         pricing_date:
      //           validCommand[validCommand.length - 1] === "ZC"
      //             ? pricingDate
      //             : undefined,
      //       }),
      //     },
      //   },
      //   () => {},
      //   () => {}
      // );

      // console.log("...---", rData);
      // // console.log("...data", rData.data)
      // const d = new GraphDataStructure();
      // d.updateState({ data: rData }, validCommand[validCommand.length - 1]);
      // setData(d);
      // console.log("...", rData);
      fetchDataWithRetry({ preparedTickers });
    }
  };

  async function fetchDataWithRetry({ retries = 3, preparedTickers }) {
    try {
      const rData = await get(
        "https://api.rumberg.ru/charts/render/",
        {
          params: {
            tickers: JSON.stringify(preparedTickers),
            command: validCommand[validCommand.length - 1],
            settings: JSON.stringify({
              start:
                validCommand[validCommand.length - 1] !== "ZC"
                  ? graphDate.graphDate
                  : undefined,
              params: data.settings,
              pricing_date:
                validCommand[validCommand.length - 1] === "ZC"
                  ? pricingDate
                  : undefined,
            }),
          },
        },
        () => {},
        () => {}
      );

      console.log("...---", rData);

      const d = new GraphDataStructure();
      d.updateState({ data: rData }, validCommand[validCommand.length - 1]);
      setData(d);
    } catch (error) {
      console.error("Error fetching data:", error);

      if (retries > 0) {
        console.log(`Retrying... Attempts left: ${retries - 1}`);
        await fetchDataWithRetry(retries - 1); // Retry with decremented retries
      } else {
        console.error("All retry attempts failed.");
      }
    }
  }

  const defaultFlow = (dates, y, tickers) => {
    const result = [];
    const strictData = {};
    const last_values = [];
    for (let ticker of tickers) {
      last_values.push({
        ticker: Array.isArray(ticker) ? ticker[0] : ticker,
        value: y[y.length - 1][tickers.indexOf(ticker)],
      });
    }
    setMetrics((prev) => ({
      ...prev,
      last_value: {
        ...prev.last_value,
        value: last_values,
      },
    }));
    tickers.map((elem, i) => {
      strictData[elem] = y.map((item) => {
        return item[i];
      });
      return undefined;
    });
    for (let elem of Object.keys(strictData)) {
      result.push({
        x: dates,
        y: strictData[elem],
        type: "scatter",
        mode: "lines",
        name: `${elem}`,
      });
    }
    return result;
  };

  const noLabelFlow = (dates, y, tickers) => {
    // пополняю вручную массив тикеров
  };

  const tooManyLabelFlow = (dates, y, tickers) => {
    // составляю необходимок количество тикеров
  };

  const candlestickFlow = (dates, y, tickers) => {
    const result = [];
    const strictData = {};
    const last_values = [];
    tickers.map((elem, i) => {
      strictData[elem[0]] = y.map((item) => {
        return item[i];
      });
      return undefined;
    });
    Object.keys(strictData).map((elem, i) => {
      last_values.push({
        ticker: elem,
        value: strictData[elem][strictData[elem].length - 1],
      });
      return undefined;
    });
    setMetrics((prev) => ({
      ...prev,
      last_value: {
        ...prev.last_value,
        value: last_values,
      },
    }));
    result.push({
      x: dates,
      open: strictData["Open"],
      close: strictData["Close"],
      high: strictData["High"],
      low: strictData["Low"],
      type: "candlestick",
      name: `${validCommand[0]}`,
      xaxis: "x",
      yaxis: "y",
    });
    return result;
  };

  const nameFlow = (dates, y, tickers) => {
    const values = y.map((elem) => [elem]);
    return defaultFlow(dates, values, tickers);
  };

  const changeEndDateIHS = (val) => {
    linkToDateEndIHS.current = val;
    setDateEndIHS(val);
  };

  const changeStartDateIHS = (val) => {
    linkToDateStartIHS.current = val;
    setDateStartIHS(val);
  };

  const changeEndDateIHST = (val) => {
    linkToDateEndIHST.current = val;
    setDateEndIHST(val);
  };

  const changeStartDateIHST = (val) => {
    linkToDateStartIHST.current = val;
    setDateStartIHST(val);
  };

  const changeEndDateIGP = (val) => {
    linkToDateEndIGP.current = val;
    setDateEndIGP(val);
  };

  const changeStartDateIGP = (val) => {
    linkToDateStartIGP.current = val;
    setDateStartIGP(val);
  };

  const changeEndDateIGPCT = (val) => {
    linkToDateEndIGPCT.current = val;
    setDateEndIGPCT(val);
  };

  const changeStartDateIGPCT = (val) => {
    linkToDateStartIGPCT.current = val;
    setDateStartIGPCT(val);
  };

  useEffect(() => {
    console.warn("MainView command is changed");
    if (
      isLiveMode &&
      !realTimeCommands.includes(validCommand[validCommand.length - 1])
    ) {
      clearInterval(liveID);
      setLiveID(null);
      setIsLiveMode(false);
    } else if (isLiveMode) {
      console.log("IsLiveMode is active");
      clearInterval(liveID);
      setIsLiveMode(false);
    }
    if (
      !isLiveMode &&
      realTimeCommands.includes(validCommand[validCommand.length - 1])
    ) {
      setIsLiveMode(true);
    }
    setChartHistory(mainView.command);
  }, [mainView.command]);

  useEffect(() => {
    console.log("[ check data ]", data, mainView.command, modesSelected);
    // setData(() => [])
    // graphDate.setGraphDate(new Date(new Date().setDate(new Date().getDate() - MODES[modesSelected])).toISOString().slice(0, 10))
    data.settings = {};

    action()
      .then((result) => {
        console.log("[ check ]", mainView.command, modesSelected, result);
        // setData(result)
      })
      .catch((err) => {
        console.error(err);
        modeInfo.setPopupStatus(true, err.message, false);
      });
  }, [
    mainView.command,
    graphDate.graphDate,
    candSelected,
    leftLotSizeIHS,
    rightLotSizeIHS,
    dateStartIHS,
    dateEndIHS,
    dateStartIGP,
    dateEndIGP,
    leftLotSizeIHST,
    rightLotSizeIHST,
    dateStartIHST,
    dateEndIHST,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setModesSelected(modes[2]);
      console.log("change mode to", mainView.command, mainView.mainView);
    }, 200);
  }, [mainView.command]);

  useEffect(() => {
    console.log("[ CHANGE MAIN VIEW ]", mainView.mainView);
    // тут запрашиваю какие поля я могу отдавать
  }, [mainView.mainView]);

  useEffect(() => {
    if (Object.keys(MODES).includes(modesSelected)) {
      graphDate.setGraphDate(
        new Date(
          new Date().setDate(new Date().getDate() - MODES[modesSelected])
        )
          .toISOString()
          .slice(0, 10)
      );
      setIsCustomMode(false);
    } else {
      setIsCustomMode(true);
    }
  }, [modesSelected]);

  useEffect(() => {
    console.log("((  ))", isLiveMode, liveID);
    if (!isLiveMode && liveID) {
      console.log("Reactivate interval");
      setLiveID(null);
      setIsLiveMode(true);
    }
    if (isLiveMode) {
      const intervalID = setInterval(() => {
        const tmp = new Date(
          new Date() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16);
        setDateEndIHS(tmp);
        setDateEndIHST(tmp);
        linkToDateEndIHS.current = tmp;
        linkToDateEndIHST.current = tmp;
        changeEndDateIGP(tmp);
        action();
      }, 15 * 1000);
      setLiveID(intervalID);
    } else {
      clearInterval(liveID);
      setLiveID(null);
    }

    return () => {
      if (isLiveMode) {
        clearInterval(liveID);
      }
    };
  }, [isLiveMode]);
  useEffect(() => {
    setTimeout(() => {
      setIsLiveMode(true);
    }, 500);
  }, []);

  const changeMode = (elem) => {
    setModesSelected(elem);
  };

  const refreshGraph = (name, value, pos) => {
    // graphParams.setGraphParams(name, value, pos)
    data.settings[name] = value;
    action().then((result) => {
      // setData(result)
      console.log("[ REFRESHED ]");
    });
  };

  const prepareData = () => {
    const result = [];
    const localData = data.preparedData();
    console.log("[ PREPARE DATA ]", localData);
    if (mainView.mainView === "GPO") {
      for (let i = 0; i < localData[0].x.length; i++) {
        result.push({
          date: localData[0].x[i],
          open: localData[0].open[i],
          close: localData[0].close[i],
          high: localData[0].high[i],
          low: localData[0].low[i],
        });
      }
    } else {
      for (let i = 0; i < localData[0].x.length; i++) {
        const preresult = {};
        for (let j = 0; j < localData.length; j++) {
          preresult[localData[j].name] = localData[j].y[i];
        }
        result.push({
          date: localData[0].x[i],
          ...preresult,
        });
      }
    }
    return result;
  };

  const downloadXLSX = () => {
    const target = prepareData();
    const ws = XLSX.utils.json_to_sheet(target);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    const xlsxData = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([xlsxData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "data.xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const setGraphDate = (value) => {
    graphDate.setGraphDate(value);
  };

  useEffect(() => {
    console.log("[ CHANGE SETTINGS FOR GRAPH ]", data.settings);
    // if (Object.keys(data.settings).length !== 0) {
    //     graphParams.setGraphParams(validCommand[validCommand.length - 1].toLowerCase(), Object.)
    // }
  }, [data.settings]);

  const [isModalActive, setIsModalActive] = useState(true);

  return (
    <>
      {/*<ModalWindow*/}
      {/*    isActive={isModalActive}*/}
      {/*    setIsActive={() => setIsModalActive(false)}*/}
      {/*    smallSize*/}
      {/*    content={*/}
      {/*        <div>*/}
      {/*            {links[validCommand[validCommand.length - 1]].queryParams.map((line, i) => {*/}
      {/*                line.map((el, j) => {*/}

      {/*                })*/}
      {/*            })}*/}
      {/*        </div>*/}
      {/*    }*/}
      {/*/>*/}
      <div
        className={`root_stock-graph ${isDes || isNonGraph ? "des" : ""} ${
          validCommand[validCommand.length - 1] === "FVT" ? "fvt" : ""
        }`}
      >
        {" "}
        {/**/}
        {!isDes &&
          validCommand[validCommand.length - 1] !== "DVD" &&
          !isNonGraph && (
            <>
              <div className={"graph-stat"}>
                <div className={"graph-short-summary"}>
                  {/*<div className={"graph-last_date"}>*/}
                  {/*    Последнее значение <br/>*/}
                  {/*    <span>{data.metrics.last_value.date}</span>*/}
                  {/*    {data.metrics.last_value.value.map((elem, i) => (*/}
                  {/*        <span key={i} style={{*/}
                  {/*            marginLeft: "10px"*/}
                  {/*        }}><b>{elem.ticker}</b>: {elem.value}</span>*/}
                  {/*    ))}*/}
                  {/*</div>*/}
                  {/*<div className={"graph-price-block"}>*/}
                  {/*    <div className={"graph-price"}>*/}
                  {/*        {metrics.last_value.value}*/}
                  {/*    </div>*/}
                  {/*    <div*/}
                  {/*        className={`graph-dynamic ${Number(metrics.previous_value.delta).toFixed(2) > 0*/}
                  {/*            ? ""*/}
                  {/*            : "downgrade"*/}
                  {/*        }`}>*/}
                  {/*        <img src={*/}
                  {/*            Number(metrics.previous_value.delta).toFixed(2) > 0*/}
                  {/*                ? upgrade*/}
                  {/*                : downgrade*/}
                  {/*        } alt={"upgrade"}/>*/}
                  {/*        {Math.abs(Number(metrics.previous_value.delta)).toFixed(2)} ({Number(metrics.previous_value.delta_percent).toFixed(2)}%)*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    flex: "auto",
                  }}
                >
                  <div
                    onClick={() => downloadXLSX()}
                    style={{
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                  >
                    <img src={download} alt="d" />
                  </div>
                  <div className={"graph-nav"}>
                    {modes.map((elem, i) => {
                      return (
                        <p
                          key={i}
                          className={`graph-nav-elem ${
                            elem === modesSelected ? "active" : ""
                          }`}
                          onClick={(e) => changeMode(e.target.textContent)}
                        >
                          {elem}
                        </p>
                      );
                    })}
                  </div>
                </div>
                {/*<div onClick={() => setIsModalActive(true)}>This</div>*/}
              </div>
              {validCommand[validCommand.length - 1] !== "FVT" &&
                validCommand[validCommand.length - 1] !== "MIVS" &&
                validCommand[validCommand.length - 1] !== "MIVST" && (
                  <div className={"graph"}>
                    {loadingGraph && (
                      <div className={"graph-loader"}>
                        <Loader />
                      </div>
                    )}
                    <Graph data={data.result} />
                  </div>
                )}
              {validCommand[validCommand.length - 1] === "FVT" && (
                <div className={"graph"}>
                  {/*{loadingGraph && <div className={"graph-loader"}><Loader/></div>}*/}
                  <ManagerMultiple command={"FVT"} params={null} />
                </div>
              )}
              {(validCommand[validCommand.length - 1] === "MIVS" ||
                validCommand[validCommand.length - 1] === "MIVST") && (
                <div className={"graph"}>
                  <ManagerMultiple
                    command={validCommand[validCommand.length - 1]}
                    params={validCommand
                      .slice(0, -1)
                      .map((elem) => elem.replaceAll("_", " "))}
                  ></ManagerMultiple>
                </div>
              )}
            </>
          )}
        {isDes && <DesData data={desData} />}
        {validCommand[validCommand.length - 1] === "DVD" && (
          <DVDData dvd={view} />
        )}
        {console.log(validCommand) &&
          validCommand[validCommand.length - 1] === "OVDV" && (
            <VolData vols={volData} />
          )}
        {isNonGraph &&
          data.table &&
          data.table.map((el) => (
            <div className={"graph-table__wrapper"}>
              {el.title && <div className="graph-table__title">{el.title}</div>}
              <div className={"graph-table__header"}>
                {el.header.map((e, i) => {
                  if (typeof e !== "object") {
                    return (
                      <div
                        style={{
                          width: "10%",
                          textAlign: "center",
                          minWidth: "100px",
                        }}
                        key={i}
                        className={"table-header"}
                      >
                        {e}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "10%",
                          textAlign: "center",
                          minWidth: "100px",
                          ...e.style,
                        }}
                        key={i}
                        className={"table-header"}
                      >
                        {e.value}
                      </div>
                    );
                  }
                })}
              </div>
              <div
                style={{
                  gap: "10px",
                }}
              >
                {el.rows.map((row) => (
                  <div className={"graph-table__row"}>
                    {row.map((elem, i) => {
                      console.log(elem, elem.value, typeof elem.value);
                      if (typeof elem !== "object") {
                        if (typeof elem === "boolean") {
                          return (
                            <div
                              style={{ width: "10%", textAlign: "center" }}
                              key={i}
                              className={"table-row"}
                            >
                              <p>{elem ? "true" : "false"}</p>
                            </div>
                          );
                        }
                        return (
                          <div
                            style={{
                              width: "10%",
                              textAlign: "center",
                              minWidth: "100px",
                            }}
                            key={i}
                            className={"table-row"}
                          >
                            <p>{elem}</p>
                          </div>
                        );
                      } else if (typeof elem.value === "boolean") {
                        return (
                          <div
                            style={{
                              width: "10%",
                              textAlign: "center",
                              minWidth: "100px",
                              ...elem.style,
                            }}
                            key={i}
                            className={"table-header"}
                          >
                            <p>{elem.value ? "true" : "false"}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              width: "10%",
                              textAlign: "center",
                              minWidth: "100px",
                              ...elem.style,
                            }}
                            key={i}
                            className={"table-row"}
                          >
                            <p>{elem.value}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        {!isNonGraph && data.table && (
          <div
            style={{
              width: "100%",
              overflow: "auto",
              position: "relative",
              top: "70%",
            }}
          >
            {data.table.map((el) => (
              <div
                style={{
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                {el.title && (
                  <div className="graph-table__title">{el.title}</div>
                )}
                <div className={"graph-table__header"}>
                  {el.header.map((e, i) => {
                    if (typeof e !== "object") {
                      return (
                        <div
                          style={{
                            width: "10%",
                            textAlign: "center",
                            minWidth: "100px",
                          }}
                          key={i}
                          className={"table-header"}
                        >
                          {e}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            width: "10%",
                            textAlign: "center",
                            minWidth: "100px",
                            ...e.style,
                          }}
                          key={i}
                          className={"table-header"}
                        >
                          {e.value}
                        </div>
                      );
                    }
                  })}
                </div>
                <div
                  style={{
                    gap: "10px",
                  }}
                >
                  {el.rows.map((row) => (
                    <div className={"graph-table__row"}>
                      {row.map((elem, i) => {
                        console.log(elem, elem.value, typeof elem.value);
                        if (typeof elem !== "object") {
                          if (typeof elem === "boolean") {
                            return (
                              <div
                                style={{ width: "10%", textAlign: "center" }}
                                key={i}
                                className={"table-row"}
                              >
                                {elem ? "true" : "false"}
                              </div>
                            );
                          }
                          return (
                            <div
                              style={{
                                width: "10%",
                                textAlign: "center",
                                minWidth: "100px",
                              }}
                              key={i}
                              className={"table-row"}
                            >
                              {elem}
                            </div>
                          );
                        } else if (typeof elem.value === "boolean") {
                          return (
                            <div
                              style={{
                                width: "10%",
                                textAlign: "center",
                                minWidth: "100px",
                                ...elem.style,
                              }}
                              key={i}
                              className={"table-header"}
                            >
                              {elem.value ? "true" : "false"}
                            </div>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                width: "10%",
                                textAlign: "center",
                                minWidth: "100px",
                                ...elem.style,
                              }}
                              key={i}
                              className={"table-row"}
                            >
                              {elem.value}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {!isDes &&
        validCommand[validCommand.length - 1] !== "DVD" &&
        !isNonGraph &&
        validCommand[validCommand.length - 1] !== "FVT" && (
          <div className={"root_stock-summary"}>
            <div className={"summary-wrapper"}>
              <div className={"summary"}>
                {realTimeCommands.includes(
                  validCommand[validCommand.length - 1]
                ) && (
                  <div className="summary-row">
                    <div className="summary-row_checkbox">
                      <label htmlFor="liveMode">Real Time</label>
                      <input
                        type="checkbox"
                        name={"liveMode"}
                        checked={isLiveMode}
                        onChange={() => {
                          setIsLiveMode(!isLiveMode);
                          clearInterval(liveID);
                          setLiveID(null);
                        }}
                      />
                    </div>
                  </div>
                )}

                {(validCommand[validCommand.length - 1] === "ZC" ||
                  validCommand[validCommand.length - 1] === "IVST" ||
                  validCommand[validCommand.length - 1] === "IVS") && (
                  <div className={"summary-row"}>
                    <InputDate
                      title={"Pricing Date"}
                      value={pricingDate}
                      withIcon={true}
                      changeCallback={(value) => setPricingDate(value)}
                    />
                  </div>
                )}

                {(validCommand[validCommand.length - 1] === "IGP" ||
                  validCommand[validCommand.length - 1] === "IGPC") && (
                  <div className={"graph-nav-cand"}>
                    {["1m", "5m", "15m", "30m", "1h"].map((elem, i) => {
                      return (
                        <p
                          key={i}
                          className={`graph-nav-elem-cand ${
                            elem === candSelected ? "active" : ""
                          }`}
                          onClick={(e) => setCandSelected(e.target.textContent)}
                        >
                          {elem}
                        </p>
                      );
                    })}
                  </div>
                )}

                {validCommand[validCommand.length - 1] === "IHS" && (
                  <div className={"summary-row"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <InputNumber
                        label={`${validCommand[0]} lot size`}
                        value={leftLotSizeIHS}
                        changeCallback={(e) => setLeftLotSizeIHS(e)}
                      />
                      <InputNumber
                        label={`${validCommand[1]} lot size`}
                        value={rightLotSizeIHS}
                        changeCallback={(e) => setRightLotSizeIHS(e)}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "20px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"Start Date"}
                          value={dateStartIHS}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => changeStartDateIHS(value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"End Date"}
                          value={dateEndIHS}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => changeEndDateIHS(value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {validCommand[validCommand.length - 1] === "IHST" && (
                  <div className={"summary-row"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <InputNumber
                        label={`${validCommand[0]} lot size`}
                        value={leftLotSizeIHST}
                        changeCallback={(e) => setLeftLotSizeIHST(e)}
                      />
                      <InputNumber
                        label={`${validCommand[1]} lot size`}
                        value={rightLotSizeIHST}
                        changeCallback={(e) => setRightLotSizeIHST(e)}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "20px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"Start Date"}
                          value={linkToDateStartIGPCT.current}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => changeStartDateIHST(value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"End Date"}
                          value={linkToDateEndIHST.current}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => changeEndDateIHST(value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(validCommand[validCommand.length - 1] === "IGP" ||
                  validCommand[validCommand.length - 1] === "IGPC" ||
                  validCommand[validCommand.length - 1] === "IGSV") && (
                  <div className={"summary-row"}>
                    <div
                      style={{
                        marginTop: "20px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"Start Date"}
                          value={linkToDateStartIGP.current}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => {
                            console.warn("VALUE OF DATE", value);
                            changeStartDateIGP(value);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"End Date"}
                          value={linkToDateEndIGP.current}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => changeEndDateIGP(value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {validCommand[validCommand.length - 1] === "IGPCT" && (
                  <div className={"summary-row"}>
                    <div
                      style={{
                        marginTop: "20px",

                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"Start Date"}
                          value={linkToDateStartIGPCT.current}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => {
                            console.warn("VALUE OF DATE", value);
                            changeStartDateIGPCT(value);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "45%",
                        }}
                      >
                        <InputDate
                          title={"End Date"}
                          value={linkToDateEndIGPCT.current}
                          withIcon={true}
                          withTime={true}
                          changeCallback={(value) => changeEndDateIGPCT(value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {isCustomMode && (
                  <div className="summary-row">
                    <InputDate
                      title={"Show data from:"}
                      value={graphDate.graphDate}
                      withIcon={true}
                      changeCallback={(e) => setGraphDate(e)}
                    />
                  </div>
                )}

                {data.settings && Object.keys(data.settings).length !== 0 && (
                  // {graphParams.graphParams[mainView.mainView.toLowerCase()] !== undefined &&
                  <div className="summary-row summary-input">
                    <div className={"summary-title"}>Параметры графика:</div>
                    <div className="summary_input-fields">
                      {Object.keys(data.settings).map((elem, i) => (
                        // {Object.keys(graphParams.graphParams[mainView.mainView.toLowerCase()]).map((elem, i) => (
                        <div className={"summary_input-field"}>
                          {/*<label key={i} className={"summary-input-value"}>{elem}</label>*/}
                          <InputNumber
                            value={data.settings[elem]}
                            changeCallback={(e) => refreshGraph(elem, e)}
                          />
                          {/*<input type="text" value={elem}*/}
                          {/*       onChange={e => graphParams.setGraphParams(elem, e.target.value)}/>*/}
                        </div>
                      ))}
                    </div>

                    {/*<div className={"summary_input-submit"} onClick={() => refreshGraph()}>Refresh</div>*/}
                  </div>
                )}

                {data.calc && data.calc.length !== 0 && (
                  <div className={"calc__card"}>
                    <div className="calc__label">Stats</div>
                    <div className="calc__line headhead">
                      <p className={"calc_name"}>Name</p>
                      <p className={"calc_value"}>Value</p>
                      <p className={"calc_result"}>Result</p>
                    </div>
                    {data.calc.map((elem, i) => (
                      <div key={i} className="calc__line">
                        <p className={"calc_name"}>{elem.name}</p>
                        <p className={"calc_value"}>{elem.value}</p>
                        <p className={"calc_result"}>{elem.result}</p>
                      </div>
                    ))}
                  </div>
                )}

                {/*<div className="chart-history_divider"></div>*/}

                {/*<div className={'summary-row'}>*/}
                {/*    <div className={"summary-title"}>Предыдущее значение*/}
                {/*        ({metrics.previous_value.date})*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={"summary-value"}>{metrics.previous_value.value}</div>*/}
                {/*</div>*/}
                {/*<div className={'summary-row'}>*/}
                {/*    <div className={"summary-title"}>Изменение за неделю</div>*/}
                {/*    <div*/}
                {/*        className={`summary-value stonks ${Number(metrics.last_week.delta_percent) > 0 ? "" : "downgrade"}`}>*/}
                {/*        <img src={*/}
                {/*            Number(metrics.last_week.delta_percent) > 0*/}
                {/*                ? upgrade*/}
                {/*                : downgrade*/}
                {/*        } alt={"stonks"}/>{Math.abs(Number(metrics.last_week.value))}*/}
                {/*        ({Number(metrics.last_week.delta_percent).toFixed(2)}%)*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'summary-row'}>*/}
                {/*    <div className={"summary-title"}>Изменение с начала года</div>*/}
                {/*    <div*/}
                {/*        className={`summary-value stonks ${Number(metrics.last_year.delta) > 0 ? "" : "downgrade"}`}>*/}
                {/*        <img src={*/}
                {/*            Number(metrics.last_year.delta) > 0*/}
                {/*                ? upgrade*/}
                {/*                : downgrade*/}
                {/*        } alt={"stonks"}/>{Math.abs(Number(metrics.last_year.delta))}*/}
                {/*        ({Number(metrics.last_year.delta_percent).toFixed(2)}%)*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'summary-row'}>*/}
                {/*    <div className={"summary-title"}>Максимум за последний год*/}
                {/*        ({metrics.max_year.date})*/}
                {/*    </div>*/}
                {/*    <div className={"summary-value"}>{metrics.max_year.value}</div>*/}
                {/*</div>*/}
                {/*<div className={'summary-row'}>*/}
                {/*    <div className={"summary-title"}>Минимум за последний год*/}
                {/*        ({metrics.min_year.date})*/}
                {/*    </div>*/}
                {/*    <div className={"summary-value"}>{metrics.min_year.value}</div>*/}
                {/*</div>*/}
              </div>
              {!IsMobile && <ChartHistory />}
            </div>
          </div>
        )}
    </>
  );
};

export default GraphWindow;
